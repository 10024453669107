.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  padding: 0 !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7::after {
  display: none !important;
}

.ReactFlagsSelect-module_selectFlag__2q5gC {
  font-size: 40px !important;
}

.ReactFlagsSelect-module_selectValue__152eS {
  padding: 0 !important;
  margin: 0 !important;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  left: unset !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

@media screen and (max-width: 767px) {
  .custom-dot-list-review {
    position: absolute !important;
    bottom: 0;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }

  .ReactFlagsSelect-module_selectOptions__3LNBJ {
    position: fixed !important;
    right: 24px !important;
    width: 300px !important;
  }
}

@media screen and (min-width: 1024px) {
  .custom-dot-list-review {
    position: absolute !important;
    left: calc(100% - 66px) !important;
    top: 50%;
    bottom: unset;
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
  }
}

.custom-dot-list-review li.react-multi-carousel-dot {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.custom-dot-list-review li.react-multi-carousel-dot button {
  height: 5px !important;
  width: 5px !important;
  background-color: #c1c2c1 !important;
  border-color: #c1c2c1;
  margin: 0;
}

.custom-dot-list-review
  li.react-multi-carousel-dot.react-multi-carousel-dot--active {
  height: 21px;
  width: 21px;
  border: 1px solid #63ab45 !important;
  border-radius: 50%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.custom-dot-list-review
  li.react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  background-color: #63ab45 !important;
  border-color: #63ab45;
}

input.react-datepicker-ignore-onclickoutside,
.tour-date input {
  border: none;
  outline: none;
}

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #000 !important;
}

.react-datepicker__input-container input::-moz-placeholder {
  color: #000 !important;
}

.react-datepicker__input-container input:-ms-input-placeholder {
  color: #000 !important;
}

.react-datepicker__input-container input::-ms-input-placeholder {
  color: #000 !important;
}

.react-datepicker__input-container input::placeholder {
  color: #000 !important;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker__day--disabled {
  -webkit-text-decoration-line: line-through !important;
  text-decoration-line: line-through !important;
  color: rgb(130, 130, 138) !important;
  font-weight: 400 !important;
}

.react-datepicker__day--selected {
  background-color: rgb(247, 146, 30) !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #f7921e !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
}

.css-13cymwt-control {
  border: none !important;
}

.css-13cymwt-control:focus-visible {
  border: none !important;
  outline: 0 !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.css-1fdsijx-ValueContainer:focus-visible {
  outline: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-t3ipsp-control {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.css-b62m3t-container:focus-visible {
  outline: none;
}

.custom-select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.custom-select__single-value {
  background-color: white;
  font-size: 18px;
}

.related-card-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 4px;
}
.custom-select__indicators {
  display: none !important;
}

.custom-select__value-container {
  padding: 0 !important;
}

.css-1wy0on6 {
  display: none !important;
}

.css-hlgwow {
  padding: 0 !important;
}

.css-19bb58m {
  margin: 0 !important;
  padding: 0 !important;
}

.number-input {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
